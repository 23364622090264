var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { LitElement, css, html } from 'lit';
import { go } from '../../router/router-mixin.js';
import { routes } from '../../router/routes.js';
import { kulaIcon, colorBars } from '../../icons/brand-icons.js';
import AppEvents from '../../app-events.js';
import { findProjectsIcon, dashboardIcon, investmentsIcon, adminIcon, reportsIcon } from '../../icons/app-icons.js';
import { getSessionUser } from '../../session/session.js';
class AppHeaderElement extends LitElement {
    constructor() {
        super();
        this.isMobile = window.innerWidth < 768;
        this.user = getSessionUser();
        this.menuOptions = [
            { label: 'Dashboard', path: routes.DASHBOARD.path, icon: dashboardIcon, disable: false },
            { label: 'Reports', path: routes.REPORTS.path, icon: reportsIcon, disable: this.isAdmin || !this.user },
            { label: 'Find Projects', path: routes.FIND_PROJECTS.path, icon: findProjectsIcon, disable: false },
            {
                label: 'My Investments',
                path: routes.MY_INVESTMENTS.path,
                icon: investmentsIcon,
                disable: this.isAdmin || !this.user,
            },
            { label: 'Admin', path: routes.ADMIN.path, icon: adminIcon, disable: this.isAdmin || !this.user },
        ];
        this.isAdmin = false;
    }
    connectedCallback() {
        const _super = Object.create(null, {
            connectedCallback: { get: () => super.connectedCallback }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.connectedCallback.call(this);
            window.addEventListener(AppEvents.ROUTE_CHANGE, () => this.requestUpdate());
            if (this.user.kind === 'Administrator') {
                this.isAdmin = true;
            }
        });
    }
    render() {
        return this.isMobile ? this.renderMobileNav() : this.renderDesktopNav();
    }
    renderMobileNav() {
        return html `
      <div class="mobile-nav">
        ${this.menuOptions.map((option) => html ` <h3
              style="fill: ${window.location.pathname == option.path ? 'var(--app-secondary-color)' : '#787773'}"
              @click=${() => go(option.path)}
            >
              ${option.icon}
            </h3>`)}
      </div>
    `;
    }
    renderDesktopNav() {
        return html `
      <div class="desktop-nav">
        <span class="header-icon">${kulaIcon}</span>
        <div class="options">
          ${this.menuOptions.map((option) => html ` <div class="option-wrap" style="display: ${option.disable ? 'none' : ''}">
                <span
                  style="fill: ${window.location.pathname == option.path ? 'var(--app-secondary-color)' : '#787773'}"
                  >${option.icon}</span
                >
                <h3
                  style="color: ${window.location.pathname == option.path ? 'var(--app-secondary-color)' : ''}"
                  @click=${() => go(option.path)}
                >
                  ${option.label}
                </h3>
              </div>`)}
        </div>
      </div>
    `;
    }
}
AppHeaderElement.properties = {
    user: { type: Object },
    isMobile: { type: Boolean },
    menuOptions: { type: Array },
    isAdmin: { type: Boolean },
};
AppHeaderElement.styles = css `
    h3 {
      cursor: pointer;
      color: var(--Grey, #787773);
      /* Subtitle */
      font-family: Lato;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 0;
      padding: 0;
      transition: color 0.1s;
    }
    h3:hover {
      color: var(--app-tertiary-color);
      transition: color 0.1s;
    }

    .header-icon {
      padding: 16px 0 0 16px;
    }
    .mobile-nav {
      position: fixed;
      bottom: 0;
      z-index: 1000;
      width: 100vw;
      height: 56px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      gap: 16px;
      background-color: var(--app-white);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    }
    .desktop-nav {
      z-index: 1000;
      position: fixed;
      display: flex;
      width: 220px;
      height: 100%;
      flex-direction: column;
      align-items: left;
      justify-content: flex-start;
      gap: 24px;
      flex-shrink: 0;
      background-color: var(--app-white);
      transition: transform 0.3s ease-in-out;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    }

    .option-wrap {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 16px;
      padding: 16px;
    }
    .options {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .bar-wrap {
      display: flex;
      flex-direction: row;
      gap: 0;
    }
    .bar1 {
      width: 50%;
      height: 20px;
      background-color: var(--app-primary-color);
    }
    .bar2 {
      width: 30%;
      height: 20px;
      background-color: var(--app-secondary-color);
    }
    .bar3 {
      width: 20%;
      height: 20px;
      background-color: var(--app-tertiary-color);
    }
  `;
export default AppHeaderElement;
customElements.define('app-header', AppHeaderElement);
