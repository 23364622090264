import { pathToRegexp } from 'path-to-regexp';
import { userIsLoggedIn } from './router-permissions.js';
/**
 * Processes a single route object and adds the pathToRegexp property.
 * @param {string} key - The key of the route object.
 * @param {Route} route - The route object to process.
 * @param {Route|null} parentRoute - The parent route object.
 * @returns {Route} - The processed route object.
 */
function processRoute(key, route, parentRoute = null) {
    var _a, _b;
    const id = route.id || key.toUpperCase();
    const lcid = id.toLowerCase().replace('_', '-');
    // Build the path of the current route, by prepending the parent route's path if available
    const path = route.path || (parentRoute ? `${parentRoute.path}/${lcid}` : `/${lcid}`);
    const componentName = route.componentName || path.replace('/', '').split('/').join('-') + '-container';
    // Build the component path of the current route, by prepending the parent route's component path if available
    const componentPath = route.componentPath || `${path}/${componentName}`.replace('/', '');
    // Any sub routes should have a back arrow
    const showBackArrow = (route === null || route === void 0 ? void 0 : route.backArrowLocation) || route.hasOwnProperty('showBackArrow') ? route.showBackArrow : !!parentRoute;
    const processed = {
        id,
        path,
        pathRegexp: pathToRegexp(path),
        componentPath,
        componentName,
        isPublic: route.isPublic || false,
        showNav: route.showNav || false,
        showBackArrow,
        showHeader: (_a = route.showHeader) !== null && _a !== void 0 ? _a : true,
        showHeaderQuickNav: route.showHeaderQuickNav || false,
        permissions: route.permissions || [],
        children: route.children || {},
        backArrowLocation: (_b = route.backArrowLocation) !== null && _b !== void 0 ? _b : (parentRoute ? parentRoute.path : null),
    };
    // After parent is initialized, we can process the child routes
    processed.children = processRoutes(processed.children, processed);
    return processed;
}
/**
 * Processes the given route objects and adds the pathToRegexp property.
 * @param {Object<string, Route>} routes - The route objects to process.
 * @param {Route|null} parentRoute - The parent route object.
 * @returns {Object<string, Route>} - The processed route objects.
 */
function processRoutes(routes, parentRoute = null) {
    return Object.entries(routes).reduce((acc, [key, route]) => {
        // @ts-ignore
        acc[key] = processRoute(key, route, parentRoute);
        return acc;
    }, {});
}
/** @type {Route} */
const ADMIN = processRoute('ADMIN', {
    showNav: true,
    permissions: [userIsLoggedIn],
    children: {
        USERS: {
            showHeaderQuickNav: true,
        },
    },
});
export const routes = processRoutes({
    LOGIN: {
        isPublic: true,
        showNav: false,
        showHeader: false,
        showHeaderQuickNav: false,
        permissions: [],
        children: {
            FORGOT_PASSWORD: {
                isPublic: true,
                showHeader: false,
                showNav: false,
            },
        },
    },
    NEW_PASSWORD: {
        isPublic: true,
        showNav: false,
        showHeader: false,
    },
    SIGNUP: {
        isPublic: true,
        showHeader: false,
        showNav: false,
    },
    ADMIN: {
        children: {
            USERS: {
                showNav: false,
                showHeader: false,
            },
            PROJECTS: {
                showNav: false,
                showHeader: false,
            },
        },
    },
    DASHBOARD: {
        showNav: true,
    },
    SANDBOX: {
        isPublic: true,
        showHeader: false,
        showNav: false,
    },
    FIND_PROJECTS: {
        isPublic: true,
    },
    MY_INVESTMENTS: {
        isPublic: true,
    },
    REPORTS: {
        isPublic: false,
    },
});
export default routes;
